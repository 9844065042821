<template>
  <div class="card card-body shadow-sm">
    <app-basic-table
      ref="basic_table"
      :table-name="$t('users.user list')"
      :filters.sync="filters"
      :setting-columns="columns"
      :endpoint-list="ENDPOINT.BOOK_MASTERS_LIST"
      @resetAllSearch="onResetAllSearch"
      @searched="onSearch"
    >
      <template v-slot:filters>
        <div class="row justify-content-center">
          <div class="col-xl-4 col-lg-6 col-md-6">
            <div class="form-group">
              <label>{{ filters.apartment_id_equal.label }}</label>
              <app-select :name="filters.apartment_id_equal.value"
                          input-style="normal"
                          v-model="filters.apartment_id_equal.value"
                          :options-data="meta.apartments"

              />
            </div>
          </div>
          <div class="col-xl-4 col-lg-6 col-md-6">
            <div class="form-group">
              <label>{{ filters.facility_id_equal.label }}</label>
              <app-select :name="filters.facility_id_equal.value"
                          input-style="normal"
                          v-model="filters.facility_id_equal.value"
                          :options-data="meta.facilities"

              />
            </div>
          </div>
        </div>

      </template>

      <template v-slot:table-menu-right>
        <button @click="handleBtnCreateClick()" class="btn btn-warning">
          {{ $t("common.create") }}
        </button>
      </template>

      <template v-slot:body-cell-apartment_id="props">
        <td class="app-align-middle">
          <p :data-original-title="props.row.apartment_name" style="color: #007bff;" @click="handlerApartmentNameClick(props.row)"  class="apartment_name app-table-p app-cell-tooltip mb-0">
            {{props.row.apartment_name}}
          </p>
        </td>
      </template>
      <template v-slot:body-cell-facility_id="props">
        <td class="app-align-middle min-w-100">
          <p :data-original-title="props.row.facility_name" style="color: #007bff;" @click="handlerFacilityNameClick(props.row)"  class="apartment_name app-table-p app-cell-tooltip mb-0">
            {{ props.row.facility_name }}
          </p>
        </td>
      </template>
      <template v-slot:body-cell-work_days="props">
        <td class="app-align-middle min-w-100 text-center">
          {{ getWorkDays(props.row.is_open_sunday, props.row.	is_open_monday, props.row.is_open_tuesday, props.row.is_open_wednesday, props.row.is_open_thursday,
          props.row.is_open_friday, props.row.is_open_saturday) }}
        </td>
      </template>
      <template v-slot:body-cell-opening_time="props">
        <td class="app-align-middle min-w-100 text-center">
          {{ getOpeningTime(props.row.opening_time) }}
        </td>
      </template>
      <template v-slot:body-cell-closing_time="props">
        <td class="app-align-middle min-w-100 text-center">
          {{ getOpeningTime(props.row.closing_time) }}
        </td>
      </template>
      <template v-slot:body-cell-unit_time="props">
        <td class="app-align-middle min-w-100 text-center">
          {{ props.row.unit_time }} 分
        </td>
      </template>
      <template v-slot:body-cell-edit="props">
        <td class="app-align-middle text-center app-p-none">
          <button
            @click="handleBtnUpdateClick(props.row)"
            class="btn btn-primary"
          >
            {{ $t("common.edit") }}
          </button>
        </td>
      </template>
    </app-basic-table>
  </div>
</template>
<script>
import AppBasicTable from "@components/_common/list/AppBasicTable";
import CreateComponent from "@pages/admin/users/_partials/CreateComponent";
import UpdateComponent from "@pages/admin/users/_partials/UpdateComponent";
import {ROLES, BASIC_TABLE} from "@constants";
import AppRadio from "../../../components/forms/AppRadio";

export default {
  name: "ListAllAccount",

  components: {
    AppRadio,
    "app-basic-table": AppBasicTable,
    "create-component": CreateComponent,
    "update-component": UpdateComponent,
  },

  data() {
    return {
      idCache: null,
      paramGetList: {},

      filters: {
        apartment_id_equal: {
          name: "apartment_id_equal",
          condition: "equal",
          label: this.$t("accounts.filter_apartment"),
        },
        facility_id_equal: {
          name: "facility_id",
          condition: "equal",
          label: this.$t("book-masters.filter_facility"),
        },
      },

      columns: [
        {name: "id", label: this.$t("common.id"), sortable: true, textAlign: 'text-center'},
        {name: "apartment_id", label: this.$t("common.mansion"), sortable: true},
        {name: "facility_id", label: this.$t("book-masters.facility"), sortable: true},
        {name: "unit_time", label: this.$t("book-masters.unit_time_list"), sortable: true},
        {name: "opening_time", label: this.$t("book-masters.opening_time"), sortable: true, textAlign: 'text-center'},
        {name: "closing_time", label: this.$t("book-masters.closing_time"), sortable: true, textAlign: 'text-center'},
        {name: "work_days", label: this.$t("book-masters.work_days"), textAlign: 'text-center'},
        {name: "unit_capacity", label: this.$t("book-masters.unit_capacity"), sortable: true, textAlign: 'text-center'},
        {name: "edit", label: this.$t("common.edit")},
      ],
      meta : [

      ]
    };
  },
  watch: {
    'filters.apartment_id_equal.value': function(value, oldValue) {
      this.getParamsFilters({apartment_id : value})
    },
  },

  created: function () {
    // this.getParamsFilters({})
  },

  methods: {
    getParamsFilters(params) {
      this.$request.get(this.ENDPOINT.UI_HELPER_FACILITIES, params).then(res => {
        this.meta = {
          apartments: res.data.apartments,
          facilities: res.data.facilities,
          facilities_all : res.data.facility_all
        }
        this.meta.apartments.unshift({
          id: "all",
          name: ""
        })
        this.meta.facilities.unshift({
          id: "all",
          name: ""
        })
        this.meta.facilities_all.unshift({
          id: "all",
          name: ""
        })
        let vm = this
        if (!this.meta.facilities.find(({id}) => id === parseInt(vm.filters.facility_id_equal.value))) {
          this.filters.facility_id_equal.value = 'all';
        }
        this.$forceUpdate();
      })
    },
    getFacilityList(apartment_id) {
      this.$request.get(this.ENDPOINT.UI_HELPER_BOOK_MASTERS_FACILITY_LIST(apartment_id)).then(res => {
        let  facilities = res.data.facilities;
        facilities.unshift({
          id: 'all',
          name: this.$t('common.all'),
        });
        if (apartment_id != 'all') {
          this.meta.facilities = facilities;
        } else {
          this.meta.facilities = [{
            id: 'all',
            name: this.$t('common.all'),
          }];
        }

      })
    },
    getOpeningTime(openingTime)
    {
      let openingTimeFirst = openingTime.substring(0,2);
      let openingTimeSecond = openingTime.substring(2);

      return openingTimeFirst+':'+openingTimeSecond
    },
    getWorkDays(isOpenSunday, isOpenMonday, isOpenTuesday, isOpenWednesday, isOpenThursday, isOpenFriday, isOpenSaturday) {
      let workdays = '';
      if (isOpenSunday) workdays = workdays + this.$t('book-masters.sunday') + '/';
      if (isOpenMonday) workdays = workdays + this.$t('book-masters.monday') + '/';
      if (isOpenTuesday) workdays = workdays + this.$t('book-masters.tuesday') + '/';
      if (isOpenWednesday) workdays = workdays + this.$t('book-masters.wednesday') + '/';
      if (isOpenThursday) workdays = workdays + this.$t('book-masters.thursday') + '/';
      if (isOpenFriday) workdays = workdays + this.$t('book-masters.friday') + '/';
      if (isOpenSaturday) workdays = workdays + this.$t('book-masters.saturday') + '/';

      return workdays.slice(0,-1);
    },
    getApartmentName(apartmentId)
    {
      let apartment = this.meta.apartments.find(({id}) => id === apartmentId);
      if (apartment == undefined) {
        return '';
      }
      return apartment.name;
    },

    getFacilityName(facilityId)
    {
      let facility = this.meta.facilities_all.find(({id}) => id === facilityId);
      if (facility == undefined) {
        return '';
      }
      return facility.name;
    },
    handleBtnUpdateClick(entry) {
      this.$router.push({name: this.ROUTES.ADMIN.BOOK_MASTER_EDIT,params:  {id: entry.id}})
    },
    handleBtnCreateClick() {
      this.$router.push({name: this.ROUTES.ADMIN.BOOK_MASTER_CREATE})
    },
    handlerApartmentNameClick(entry) {
      let routeApartment = this.$router.resolve({name: this.ROUTES.ADMIN.APARTMENT_EDIT, params: {id: entry.apartment_id}});
      window.open(routeApartment.href, '_blank');
    },
    handlerFacilityNameClick(entry) {
      let routeFacility = this.$router.resolve({name: this.ROUTES.ADMIN.FACILITY_EDIT, params: {id: entry.facility_id}});
      window.open(routeFacility.href, '_blank');
    },
    onResetAllSearch() {
      this.$router.push({name: this.$route.name,
        query : {
          'filters.apartment_id_equal.equal' : 'all',
          'filters.facility_id.equal' : 'all',
        }
      })
    },
    onSearch() {
      // this.getTourSelectName();
    },
  },
};
</script>
